import React, { useState, useEffect } from "react";
import "./ServicesSection.css";
import ServiceData from "./ServiceData";
import TabComponent from "../../../components/Service/TabComponent/TabComponent";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { ServiceTabDetail } from "../../../components/Service/TabDetail/TabDetail";

/**
 * @author
 * @function ServiceSection
 **/

export const ServicesSection = (props) => {
  const [screenDimension, setScreenDimension] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [height, setHeight] = useState(window.innerHeight - 100);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screen = "";
      if (window.innerWidth < 1200 && window.innerWidth > 1000) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 990) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const getColoumnClass = (dim) => {
    if (dim === "tablet" || dim === "") {
      return "col-4";
    } else {
      return "col-6";
    }
  };

  // Tab List Functions

  const handleClick =
    (index) =>
    ({ getItemById, scrollToItem }) => {
      setSelectedTab(index);
    };

  const renderServiceDetail = () => {
    //const { tagline, paragraph1, moduleImg, paragraph2, serviceImg, colorCode} = ServiceData[selectedTab].serviceDetailProp

    return (
      <ServiceTabDetail
        serviceItem={ServiceData[selectedTab].serviceDetailProp}
      />
    );
  };
  const renderTabs = () => {
    return (
      <div>
        <ScrollMenu scrollContainerClassName='react-horizontal-scrolling-menu--scroll-contain'>
          {ServiceData.map(({ heading, serIcon, serBwIcon }, index) => (
            <TabComponent
              tabClass={
                selectedTab === index
                  ? "tab-component"
                  : "tab-component-disabled"
              }
              headingClass={
                selectedTab === index ? "tab-heading" : "tab-heading-disabled"
              }
              itemId={index} // NOTE: itemId is required for track items
              title={index}
              key={index}
              onClick={handleClick(index)}
              heading={heading}
              indIcon={selectedTab === index ? serIcon : serBwIcon}
              imgClass={
                selectedTab === index
                  ? "tab-diamond-img-img"
                  : "tab-diamond-img-img-disabled"
              }
            />
          ))}
        </ScrollMenu>

        {/* Write All teh bottom tab code from here */}
      </div>
    );
  };

  return (
    <div className='services-section' id='services-section'>
      <div className='content'>
        <div className='container'>
          {/* heading */}
          <h1 className='services-section-heading'>SERVICES</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* Horizontal Scroll */}
          {renderTabs()}
          {renderServiceDetail()}
        </div>
      </div>
    </div>
  );
};
