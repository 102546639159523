import React, { useEffect, useRef } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import "./App.css";
import HomeCarousel from "./pages/sections/HomeSection/HomeCarousel";
import NavBar from "./components/Navbar/NavBar";
import PortfolioSection from "./pages/sections/PortfolioSection/PortfolioSection";
import { OurClients } from "./pages/sections/Clients/OurClients";
import TeamSection from "./pages/sections/TeamSection/TeamSection";
import { Footer } from "./pages/sections/Footer/Footer";
import { ContactUs } from "./pages/sections/ContactUs/ContactUs";
import { WorkSection } from "./pages/sections/WorkSection/WorkSection";
import { IndustrySection } from "./pages/sections/Industries/IndustrySection";
import { ServicesSection } from "./pages/sections/ServicesSection/ServicesSection";
import VisibilitySensor from "react-visibility-sensor";
function App() {
  useEffect(() => {
    Aos.init({ duration: 2200 });
  }, []);

  return (
    <div className='App'>
      <div style={{ "margin-top": "5rem" }}>
        <div>
          <NavBar />
        </div>

        <div>
          <HomeCarousel />
        </div>

        <div data-aos='fade-up'>
          <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
            {({ isVisible }) => {
              console.log("Visibitlity is ", isVisible);
              return <PortfolioSection />;
            }}
          </VisibilitySensor>
        </div>

        <div data-aos='zoom-in-up'>
          <WorkSection />
        </div>

        <div data-aos='slide-left'>
          <ServicesSection />
        </div>

        <div data-aos='slide-right'>
          <IndustrySection />
        </div>

        <div data-aos='fade-up'>
          <TeamSection />
        </div>

        <div data-aos='fade-up'>
          <OurClients />
        </div>

        <div data-aos='fade-down-right'>
          <ContactUs />
        </div>

        <div data-aos='fade-up'>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
