import React from 'react'
import { ContactCardData } from '../ContactCard/ContactCardData'
import { ContactCard } from '../ContactCard/ContactCard'


/**
* @author
* @function ContactCardList
**/
 
export const ContactCardList = () => {
  return(
    ContactCardData.map((item)=>{
        return (
            <ContactCard
            CardDataItem={item}
            />
        )
    })
   )

 }