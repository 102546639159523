//======================= I N D U S T R I E S - MODS ==================================

import indModautomotive from '../../../images/IndMod-automotive.svg';
import indModcateringHospitality from '../../../images/IndMod-catering&Hospitality.svg'
import indModeducation from '../../../images/IndMod-education.svg'
import indModembroidery from '../../../images/IndMod-embroidery.svg'
import indModfoodAndBeverages from '../../../images/IndMod-foodAndBeverages.svg'
import indModhealthcare from '../../../images/IndMod-healthcare.svg'
import indModnonProfit from '../../../images/IndMod-nonProfit.svg'
import indModoilAndGas from '../../../images/IndMod-oilAndGas.svg'
import indModpaintAndCoating from '../../../images/IndMod-paintAndCoating.svg'
import indModpoultry from '../../../images/IndMod-poultry.svg'
import indModrealEstate from '../../../images/IndMod-realEstate.svg'
import indModrugsAndCarpets from '../../../images/IndMod-rugsAndCarpets.svg'
import indModsurgicalEquipments from '../../../images/IndMod-surgicalEquipments.svg'
import indModtextileApparel from '../../../images/IndMod-textile&Apparel.svg'
import indModtradingRetail from '../../../images/IndMod-trading&Retail.svg'

//======================= I N D U S T R I E S - NICHES ==================================

import indNautomotive from '../../../images/2 automotive.png';
import indNcateringHospitality from '../../../images/7 catering and hospitality.png'
import indNeducation from '../../../images/6 education.png'
import indNembroidery from '../../../images/9 embroidery.png'
import indNfoodAndBeverages from '../../../images/14 food and beverages.png'
import indNhealthcare from '../../../images/5 healthcare.png'
import indNnonProfit from '../../../images/8 non profit.png'
import indNoilAndGas from '../../../images/1 oil and gas.png'
import indNpaintAndCoating from '../../../images/15 paint and coating.png'
import indNpoultry from '../../../images/11 poultry.png'
import indNrealEstate from '../../../images/10 real estate.png'
import indNrugsAndCarpets from '../../../images/12 rugs and carpets.png'
import indNsurgicalEquipments from '../../../images/13 surgical eguipment.png'
import indNtextileApparel from '../../../images/3 textile and apparel.png'
import indNtradingRetail from '../../../images/4 trading and retail.png'

//======================= I N D U S T R I E S - ICONS ==================================

import indautomotive from '../../../images/Ind-automotive.svg';
import indcateringHospitality from '../../../images/Ind-catering&Hospitality.svg'
import indeducation from '../../../images/Ind-education.svg'
import indembroidery from '../../../images/Ind-embroidery.svg'
import indfoodAndBeverages from '../../../images/Ind-foodAndBeverages.svg'
import indhealthcare from '../../../images/Ind-healthcare.svg'
import indnonProfit from '../../../images/Ind-nonProfit.svg'
import indoilAndGas from '../../../images/Ind-oilAndGas.svg'
import indpaintAndCoating from '../../../images/Ind-paintAndCoating.svg'
import indpoultry from '../../../images/Ind-poultry.svg'
import indrealEstate from '../../../images/Ind-realEstate.svg'
import indrugsAndCarpets from '../../../images/Ind-rugsAndCarpets.svg'
import indsurgicalEquipments from '../../../images/Ind-surgicalEquipments.svg'
import indtextileApparel from '../../../images/Ind-textile&Apparel.svg'
import indtradingRetail from '../../../images/Ind-trading&Retail.svg'

const IndustryData = [
    {
        heading:'Oil & Gas',
        indIcon:indoilAndGas,
        industryModalProp:{
            title:'Oil & Gas',
            paragraph1:'Oil & Gas companies are operating in more challenging environments and feel the need for operational excellence which requires greater visibility & single source of truth for timely decision-making to reduce risks & costs.',
            paragraph2:'Easy Solutions™ FISPRO enterprise suite is a comprehensive IP-based modular solution brings the power of real-time analytics, predictive analytics, spatial analytics / locational intelligence, scenario analysis etc. A powerful, mature, stable and comprehensive yet flexible & scalable solution with the capability of hosting on-cloud or on premise, to manage all aspects of the Oil & Gas Industry.',
            moduleImg:indModoilAndGas,
            nicheImg:indNoilAndGas,
            colorCode:'#6a4094'
        }
    },
    {
        heading:'Automotive',
        indIcon:indautomotive,
        industryModalProp:{
            title:'Automotive',
            paragraph1:'Every automobile dealer must have a system in place that clearly measures, reports, evaluates and controls the functions within a dealership. Easy Solutions™ FISPRO ERP deals with all the requirements of Automotive Dealer Organization like Vehicle Sales, Workshop Activities, Spare Parts & Accessories Inventory and Financial Accounting. The tight integration of FISPRO ERP modules automatically avoids the duplication of entries and generates maximum reports as an output from minimum basic input.',
            paragraph2:'Easy Solutions™ FISPRO enables businesses into the automotive industry solutions to stay competitive and meet their sales goals by leveraging customized IT solutions like automobile software, web & apps development solution considering futuristic trends and technology.',
            moduleImg:indModautomotive,
            nicheImg:indNautomotive,
            colorCode:'#8c4d2f'
        }
    },
    {
        heading:'Textile & Apparel',
        indIcon:indtextileApparel,
        industryModalProp:{
            title:'Textile & Apparel',
            paragraph1:'With the technological boom in the past years, the textile industries also changed incorporating numerous methods of updated technology. By understanding the needs of current Textile era, we have successfully introduced our product for Textile named FISPRO ERP.',
            paragraph2:'Easy Solutions™ FISPRO is used to manage the business planning, development, manufacturing, distribution as well as selling. FISPRO ERP operates on different levels starting from HR, customer relations, finance, accounting, generation of payrolls. It is also possible to customize FISPRO ERP according to specific business needs, which is one of the major advantages of using FISPRO ERP.',
            moduleImg:indModtextileApparel,
            nicheImg:indNtextileApparel,
            colorCode:'#faa841'
        }
    },
    {
        heading:'Trading & Retail',
        indIcon:indtradingRetail,
        industryModalProp:{
            title:'Trading & Retail',
            paragraph1:'Easy Solutions™ FISPRO for Trading & Retail business also known as FISPRO POS is a leading computer-based enterprise solution. This package consists of integrated business application with better decision making-tools and provides an endto-end solution that organizes and controls every aspect of business from order management, operations, supply-chain and logistics, to documentation, accounting and business intelligence reporting',
            paragraph2:'Leading brand retailers in various segments such as Lifestyle, apparels, food & beverages, sporting goods, gift stores, art gallery, jewelry, electronics, hardware, footwear, books, bakery, beauty & cosmetics, have trusted us for our customized FISPRO POS.',
            moduleImg:indModtradingRetail,
            nicheImg:indNtradingRetail,
            colorCode:'#ca3653'
        }
    },
    {
        heading:'Healthcare',
        indIcon:indhealthcare,
        industryModalProp:{
            title:'Healthcare',
            paragraph1:'The healthcare industry is focused on improving the quality of healthcare and operational eficiency while reducing costs and optimizing back-end operations.',
            paragraph2:'Leading brand retailers in various segments such as Lifestyle, apparels, food & beverages, sporting goods, gift stores, art gallery, jewelery, electronics, hardware, footwear, books, bakery, beauty & cosmetics, have trusted us for our customized FISPRO POS.',
            moduleImg:indModhealthcare,
            nicheImg:indNhealthcare,
            colorCode:'#14aae1'
        }
    },
    {
        heading:'Education',
        indIcon:indeducation,
        industryModalProp:{
            title:'Education',
            paragraph1:'Education is one such name where technology and use of computers have changed the way it is delivered. Other than various new learning techniques like online courses, smart classrooms; technology has equally simpliï€ed the management of educational institutions. Similarly, ERP implementation has been helping educational institutions to improve their operations, making them manageable and more transparent.',
            paragraph2:'Easy Solutions™ FISPRO designed for academic & professional educational institutions provide user-friendly dashboards with login access for teaching, non-teaching staff, students, parents and management. The various modules available in FISPRO ERP facilitate all the processes of educational institutions from admission inquiry to generating transcripts & certificates.',
            moduleImg:indModeducation,
            nicheImg:indNeducation,
            colorCode:'#00a699'
        }
    },
    {
        heading:'Catering & Hospitality',
        indIcon:indcateringHospitality,
        industryModalProp:{
            title:'Catering & Hospitality',
            paragraph1:'With Easy Solutions™ FISPRO you can track all the details related to your catered events, including menus, pricing, event halls, rentals,staffing and other resources.',
            paragraph2:'FISPRO ERP is easy to use venue and event management solution. It increases the business profit by maintaining the customer database, inventory control, taxes, fnancial analysis, and many more. Thereby helping you to save time, money & energy. A smooth & friendly user experience that has been built to ensure the user seamless adoption.',
            moduleImg:indModcateringHospitality,
            nicheImg:indNcateringHospitality,
            colorCode:'#47c3d0'
        }
    },
    {
        heading:'Non Profit',
        indIcon:indnonProfit,
        industryModalProp:{
            title:'Non Profit',
            paragraph1:'Easy Solutions™ FISPRO is a comprehensive software especially customized for the Not for Profit Organizations (NPOs) with a special focus on donor reporting and related internal control requirements',
            paragraph2:'The software includes fully integrated modules for HR (including full payroll cycle), project/grant budget management, fixed assets register etc. The software is user-friendly and is designed to increase efficiency and transparency than ever before by delivering real-time and reliable financial and operational information across all information users/decision-makers aspects in the organization.<br>FISPRO ERP has a special focus on different requirements from the donors and hence facilitates the external financial evaluations and gain the trust of donors.',
            moduleImg:indModnonProfit,
            nicheImg:indNnonProfit,
            colorCode:'#ab4a9a'
        }
    },
    {
        heading:'Embroidery',
        indIcon:indembroidery,
        industryModalProp:{
            title:'Embroidery',
            paragraph1:'Easy Solutions™ FISPRO for Embroidery provides the best solution for not only accountancy; it also maintains inventories upto-date for customers. This software enables to categorize and maintain stocks of different fabric varieties, processing / embroider speciï€c lots, and auto generated composite invoices for different deliveries.',
            paragraph2:'This integrated accounting system with production, makes it easy to maintain customer ledgers and recoveries automatically with invoicing.',
            moduleImg:indModembroidery,
            nicheImg:indNembroidery,
            colorCode:'#edbc4b'
        }
    },
    {
        heading:'Real Estate',
        indIcon:indrealEstate,
        industryModalProp:{
            title:'Real Estate',
            paragraph1:'Easy Solutions™ FISPRO works to simplify your real estate back office by providing robust transaction management, various financial calculations and accounting integration all in one easy-to-use platform.',
            paragraph2:'FISPRO ERP provides agents, office administrators, and management true visibility into each real estate transaction and keeps track of all listings, pending sales, and rent / lease transactions. In addition FISPRO ERP has various components that make it categorically THE software to efficiently support a real estate organization of any size.',
            moduleImg:indModrealEstate,
            nicheImg:indNrealEstate,
            colorCode:'#2957a4'
        }
    },
    {
        heading:'Poultry',
        indIcon:indpoultry,
        industryModalProp:{
            title:'Poultry',
            paragraph1:'Poultry management software enhances operational capabilities and boosts transparency in the reporting process from production and profits to various rules and regulations.',
            paragraph2:'Easy Solutions™ FISPRO for poultry comprises of various modules. The solution enables managing of poultry business efficiently and effectively through a centralized system.',
            moduleImg:indModpoultry,
            nicheImg:indNpoultry,
            colorCode:'#7ea54c'
        }
    },
    {
        heading:'Rugs & Carpets',
        indIcon:indrugsAndCarpets,
        industryModalProp:{
            title:'Rugs & Carpets',
            paragraph1:'Easy Solutions™ FISPRO for handmade carpet manufacturers is highly advanced software having various modules from the manufacturing to the export shipment of carpets, handicrafts, rugs and more.',
            paragraph2:'FISPRO ERP can play a very important part in providing business insights to help drive the business forward.',
            moduleImg:indModrugsAndCarpets,
            nicheImg:indNrugsAndCarpets,
            colorCode:'#ce4b3e'
        }
    },
    {
        heading:'Surgical Equipments',
        indIcon:indsurgicalEquipments,
        industryModalProp:{
            title:'Surgical Euipments',
            paragraph1:'Easy Solutions™ ultimate goal is to simplify the manufacturing process for our customers, improving efficiency while increasing profits. <b>Easy Solutions™ FISPRO</b> automated inventory control with the management of planning and scheduling for the shop floor achieves both of these goals.',
            paragraph2:'Each module is designed and engineered by our team to give manufacturers control over every factor affecting their cost of goods. FISPRO ERP makes it simpler to see how each area of your business is affecting the big picture.',
            moduleImg:indModsurgicalEquipments,
            nicheImg:indNsurgicalEquipments,
            colorCode:'#0f6395'
        }
    },
    {
        heading:'Food & Beverages',
        indIcon:indfoodAndBeverages,
        industryModalProp:{
            title:'',
            paragraph1:'For food and beverage companies, business is always a race. Quality control, raw material and base food costs and overall margin pressures force food & beverage manufacturers to track inventory at every step of the supply chain.',
            paragraph2:'Easy Solutions™ FISPRO enables food & beverage manufacturers to effectively track raw materials to finished goods. FISPRO ERP offers an end-to-end solution with deep, proven functionality for all critical business processes',
            moduleImg:indModfoodAndBeverages,
            nicheImg:indNfoodAndBeverages,
            colorCode:'#46b553'
        }
    },
    {
        heading:'Paint & Coating',
        indIcon:indpaintAndCoating,
        industryModalProp:{
            title:'Paint & Coating',
            paragraph1:'Coupons and deals are part of any business, especially in the e-Commerce era. But if your promotion can not stand out from the rest, it will simply be static in a sea of discounts.',
            paragraph2:'Easy Solutions offers you the ability to truly advance your brand and your business all at the same time. By creating, coupons with <b>Easy Solutions™ FISPRO</b>, you are not just offering customers a discount , you are offering a passport to your brand community, and that is the real power of creativity.',
            moduleImg:indModpaintAndCoating,
            nicheImg:indNpaintAndCoating,
            colorCode:'#ed2d6a'
        }
    },
    
    
]

export default IndustryData