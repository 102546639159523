import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import HomeComponent from "../../../components/HomeComponent/HomeComponent";
import HomeCarouselImage1 from "../../../images/home-Carousel-img1.png";

import "./HomeSection.css";
import { HomeSectionData } from "./HomeSectionData";

const dataType = "v1";

function HomeCarousel() {
  const [height, setHeight] = useState(window.innerHeight);
  const renderCarousalItem = () => {
    return HomeSectionData.map((item) => {
      return (
        <Carousel.Item interval={2000}>
          <div className='home-carousal-parent'>
            <HomeComponent item={item} />
          </div>
        </Carousel.Item>
      );
    });
  };

  return (
    <div className='home-section' style={{ height }}>
      <Carousel variant='dark' indicators={false} id='home'>
        {renderCarousalItem()}
      </Carousel>
    </div>
  );
}

export default HomeCarousel;
