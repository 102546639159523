import React from 'react'
import './FCI.css'

/**
* @author
* @function FooterContactInfo
**/

export const FooterContactInfo = ({ infoItem }) => {

    const { iconSrc, contactInfo } = infoItem

    return (
        <div className='row'>
            <div className='col-1'>
                <img
                    src={iconSrc}
                    alt='icon'
                />
            </div>

            <div className='col'>
                <p className='contactinfoP'>{contactInfo}</p>

            </div>

        </div>
    )

}