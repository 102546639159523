import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { PortfolioCard } from "../../../components/portfolioCard/PortfolioCard";
import "./Portfolio.css";
import portfolioData from "./PortfolioData";
import useIntersection from "../../../hooks/visibilityHook";

function PortfolioSection() {
  const [screenDimension, setScreenDimension] = useState("");
  const [height, setHeight] = useState(window.innerHeight - 80);
  console.log("The screen Dimension", screenDimension);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      let screen = "";
      if (window.innerWidth < 1200 && window.innerWidth > 760) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 760) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const renderPortfolioItem = (dim) => {
    return portfolioData.map((item, index) => {
      console.log("The FUnction Returns", getBootStrapClassName(dim));
      return (
        <PortfolioCard
          className={getBootStrapClassName(dim, index)}
          cardItem={item}
          itemNumber={getSpacingClass(index, screenDimension)}
        />
      );
    });
  };

  function getSpacingClass(index, screenDimension) {
    if (screenDimension === "tablet") {
      if (index === 0 || index === 2) {
        return "itemTwoAndFour";
      } else if (index === 4 || index === 3) {
        return "itemOneAndFive";
      } else return "itemThree";
    } else if (screenDimension === "mobile") {
      return "itemThree";
    } else {
      if (index === 0 || index === 4) {
        return "itemOneAndFive col-center-block";
      } else if (index === 1 || index === 3) {
        return "itemTwoAndFour col-center-block";
      } else return "itemThree col-center-block";
    }
  }

  function getBootStrapClassName(screenDimension, index) {
    console.log("Get BootTsrap Function ", screenDimension);
    if (screenDimension === "tablet") {
      if (index === 3 || index === 4) {
        return "col-4 col-center-block";
      }
      return "col-4";
    } else if (screenDimension === "mobile") {
      return "col-12";
    } else {
      return "col ";
    }
  }

  return (
    <div className='portfolio' id='portfolio' style={{ height: "90%" }}>
      <div className='content'>
        <Container>
          {/* heading */}
          <h1 className='pheading'>OUR PORTFOLIO</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* card design */}

          <div className='row portfolioItemContainer'>
            {renderPortfolioItem(screenDimension)}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default PortfolioSection;
