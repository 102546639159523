import React from 'react'
import '../../Service/TabDetail/TabDetail.css'

/**
* @author
* @function ServiceTabDetail
**/

export const ServiceTabDetail = ({ serviceItem }) => {
    const { tagline, paragraph1, moduleImg, paragraph2, serviceImg, colorCode } =
        serviceItem;
    return (
        <div className='row container service-detail-border'>
            
                <div className='col-8'>
                    <div className='row'>
                        <p style={{ color: colorCode }}><b>{tagline}</b></p>
                    </div>
                    <div className='row'>
                        <p style={{ color: colorCode }}>{paragraph1}</p>
                    </div>
                    <div className='row service-module'>
                        <img
                            src={moduleImg}
                            alt='IndmodImage'
                        />
                    </div>
                    <div className='row'>
                        <p style={{ color: colorCode }}>{paragraph2}</p>
                    </div>
                </div>
                <div className='col-4'>
                    <img
                        className='service-ai'
                        src={serviceImg}
                        alt='ServiceNicheImage'
                    />
                </div>
        

        </div>
    )

}