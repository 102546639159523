import React from "react";
import "./HomeServiceComponent.css";
function HomeServiceComponent({ item }) {
  const { imgSource, color, title } = item;
  return (
    <div className='home-service-comp'>
      <div className='home-service-img-div' style={{ background: `${color}` }}>
        <img src={imgSource} />
      </div>

      <h1> {title} </h1>
    </div>
  );
}

export default HomeServiceComponent;
