import React from "react";
import bottom_diamond from "../../../images/bottom_diamond.svg";
import "./TabComponent.css";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
function TabComponent({
  indIcon,
  heading,
  onClick,
  tabClass,
  headingClass,
  imgClass,
  title,
}) {
  const visibility = React.useContext(VisibilityContext);

  const getImageContainerClassName = () => {
    if (title === 1 || title === 3 || title === 5) {
      return "tab-diamond-img-lg";
    }

    return "tab-diamond-img";
  };
  return (
    <div className={"enclosing-tab "}>
      <div
        className={tabClass}
        onClick={() => onClick(visibility)}
        tabIndex={0}
      >
        <div className='tab-img'>
          <img src={indIcon} alt='First slide' />
        </div>

        <div>
          <h1 className={headingClass}>{heading}</h1>
        </div>

        <div className={getImageContainerClassName()}>
          <img src={bottom_diamond} alt='First slide' className={imgClass} />
        </div>
      </div>
    </div>
  );
}

export default TabComponent;
