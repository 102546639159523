import React from "react";
import "./HomeComponent.css";
import HomeServiceComponent from "./HomeServiceComponent/HomeServiceComponent";
function HomeComponent({ item }) {
  const { imgs, bottomItems } = item;

  const renderTopComponent = () => {
    return imgs.map((item) => {
      return <img src={item} />;
    });
  };

  const renderBottomComponent = () => {
    return bottomItems.map((item) => {
      return <HomeServiceComponent item={item} />;
    });
  };

  return (
    <div className='home-component'>
      {/* Top Section */}

      <div className='top-section'>{renderTopComponent()}</div>

      {/* bottom section */}
      <div className='bottom-section'>{renderBottomComponent()}</div>
    </div>
  );
}

export default HomeComponent;
