import React from "react";

/**
 * @author
 * @function ClientCard
 **/

export const ClientCard = ({ className, imgSource }) => {
  return (
    <div className={className}>
      <img
        style={{
          alignSelf: "stretch",
          width: "15rem",
          boxShadow: "rgba(0,0,0,0.2) 0 0 10px",
          borderRadius: "20px",
        }}
        src={imgSource}
        alt='First slide'
      />
    </div>
  );
};
