import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { WorkCard } from "../../../components/WorkCard/WorkCard";
import { WorkCardData, MobileSizeWorkData } from "./WorkCardData";

import "./WorkSection.css";

/**
 * @author
 * @function OurClients
 **/

export const WorkSection = (props) => {
  const [screenDimension, setScreenDimension] = useState("");

  console.log("The screen Dimension", screenDimension);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screen = "";

      if (window.innerWidth < 1200 && window.innerWidth > 1000) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 990) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const renderWorkItem = (dim, startIndex, endIndex) => {
    let cards = [];
    var className = "";
    if (dim === "tablet" || dim === "") {
      className =
        startIndex < 4
          ? `col-3 work-custom-width `
          : "col-3 col-center-block work-custom-width";
    } else {
      className =
        startIndex < 4
          ? `col-6 work-custom-width `
          : "col-6 col-center-block work-custom-width";
    }

    for (let i = startIndex; i < endIndex; i++) {
      var cardData = {};
      if (i < 4) {
        cardData = WorkCardData[i];
      } else if (i >= 4 && dim === "mobile") {
        console.log("The Card Data is ", i, dim);
        cardData = MobileSizeWorkData[i - 4];
      } else {
        cardData = WorkCardData[i];
      }

      // console.log("The Card Data is ", i, dim);

      cards.push(
        <WorkCard className={`${className} work-item`} workItem={cardData} />
      );
    }

    return cards;
  };

  // getBootStrapClassName(dim, i)

  // function getBootStrapClassName(screenDimension, index) {
  //   console.log("Get BootTsrap Function ", screenDimension);
  //   if (screenDimension === "tablet") {
  //     return "col-4 work-item";
  //   } else if (screenDimension === "mobile") {
  //     return "col work-item";
  //   } else {
  //     return "col-4 work-item";
  //   }
  // }

  return (
    <div className='work-section' id='work-section' style={{ height: "90%" }}>
      <div className='content'>
        <Container>
          {/* heading */}
          <h1 className='heading text-color'>HOW WE WORK</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* card design */}

          <div className='col-12'>
            <div className='row portfolioItemContainer'>
              {renderWorkItem(screenDimension, 0, 4)}
            </div>

            <div className='row portfolioItemContainer'>
              {renderWorkItem(screenDimension, 4, WorkCardData.length)}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
