import React from "react";
import "../ContactCard/ContactCard.css";

/**
 * @author
 * @function ContactCard
 **/

export const ContactCard = ({ CardDataItem }) => {
  const { image, description, Info1, Info2, Info3 } = CardDataItem;

  // return (
  //   <div className='col contactCard-card'>
  //     <div className='row '>
  //       <div className='contactcard-div'>
  //         <img className='contactcard-icon' src={image} alt='callIcon' />
  //       </div>
  //     </div>

  //     <div className='row contactCard-TextHolder'>
  //       <div className='col smallcard'>
  //         <p className='contactCard-description'>{description}</p>
  //       </div>
  //       <div className='col contactCard-text smallcard'>
  //         <div className='col'>{Info1}</div>
  //         <div className='col'>{Info2}</div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    // <div className='col contactCard-card'>
      
    // </div>
    <div className='row contactCard-TextHolder'>
    <div className='col-2 contactcard-iconHolder'>
      <img className='contactcard-icon' src={image} alt='callIcon' />
    </div>
    <div className='col smallcard'>
      <p className='contactCard-description'>{description}</p>
    </div>
    <div className='col contactCard-text smallcard'>
      <div className='col'>{Info1}</div>
      <div className='col'>{Info2}</div>
      <div className='col'>{Info3}</div>
    </div>
  </div>
  );
};
