import React from "react";
import { Button, Modal } from "react-bootstrap";
import '../../components/Modal/Modal.css'

/**
 * @author
 * @function MyVerticallyCenteredModal
 **/

export const MyVerticallyCenteredModal = ({ modalItem, onHide, show }) => {
  const { title, paragraph1, paragraph2, moduleImg, nicheImg, colorCode } =
    modalItem;

  return (
    <Modal 
      
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton style={{ backgroundColor: colorCode }}>
        <Modal.Title style={{ color: "white" }} id='contained-modal-title-vcenter'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-7'>
            <div className='row'>
              <p style={{ color: colorCode }}>{paragraph1}</p>
              <p style={{ color: colorCode }}>{paragraph2}</p>
            </div>
            <div className='row'>
              <img
              className='modal-mod'
              src={moduleImg}
              alt='IndmodImage'
              /></div>
          </div>
          <div className='col-5 modal-img-ai'>
            <img
            className='modal-img-ai'
            src={nicheImg}
            alt='IndnicheImage'
            />
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
