import React from "react";
import Button from "react-bootstrap/Button";

export default function OutlineButton(props) {
  return (
    <>
      <Button
        value={props.value}
        onClick={(e) => props.onClick(e)}
        variant='outline-secondary'
        style={styles.buttonStyle}
      >
        {props.text}
      </Button>
    </>
  );
}

const styles = {
  buttonStyle: { flex: 1, borderRadius: "10px" },
};
