import Work1 from "../../../images/Work1.svg";
import Work2 from "../../../images/Work2.svg";
import Work3 from "../../../images/Work3.svg";
import Work4 from "../../../images/Work4.svg";
import Work5 from "../../../images/Work5.svg";
import Work6 from "../../../images/Work6.svg";
import Work7 from "../../../images/Work7.svg";
import arrowRight from "../../../images/arrowRight.svg";
import arrowUp from "../../../images/arrowUp.svg";
import arrowDown from "../../../images/arrowDown.svg";
import arrowLeft from "../../../images/arrowLeft.svg";

export const WorkCardData = [
  {
    imgSource: Work1,
    heading: "You Have An Idea",
    number: "01",
    arrowImage: arrowRight,
  },
  {
    imgSource: Work2,
    heading: "Lets Brainstorm",
    number: "02",
    arrowImage: arrowRight,
  },
  {
    imgSource: Work3,
    heading: "Making Prototype",
    number: "03",
    arrowImage: arrowRight,
  },
  {
    imgSource: Work4,
    heading: "UI Design",
    number: "04",
    arrowImage: arrowDown,
  },
  {
    imgSource: Work7,
    heading: "Launch",
    number: "07",
    arrowImage: arrowUp,
  },
  {
    imgSource: Work6,
    heading: "QA and testing",
    number: "06",
    arrowImage: arrowLeft,
  },
  {
    imgSource: Work5,
    heading: "Software development",
    number: "05",
    arrowImage: arrowLeft,
  },
];

export const MobileSizeWorkData = [
  {
    imgSource: Work6,
    heading: "QA and testing",
    number: "06",
    arrowImage: arrowDown,
  },
  {
    imgSource: Work5,
    heading: "Software development",
    number: "05",
    arrowImage: arrowLeft,
  },
  {
    imgSource: Work7,
    heading: "Launch",
    number: "07",
    arrowImage: arrowUp,
  },
];
