import React from "react";
import "./Footer.css";
import upToTop from '../../../images/footer-up.svg'

import { FooterContactInfo } from "../../../components/FooterComponents/FooterContactInfo/FooterContactInfo";
import { FooterMainElement } from "../../../components/FooterComponents/FooterMainElement/FooterMainElement";
import { FooterServiceLinks } from "../../../components/FooterComponents/FooterServiceLinks/FooterServiceLinks";
import { FooterSocialLinks } from "../../../components/FooterComponents/FooterSocialLinks/FooterSocialLinks";

import {
  FooterContactInfoData,
  FooterServiceLinksData,
  FooterMainElementData,
  FooterSocialLinksData,
} from "./FooterData";

/**
 * @author
 * @function Footer
 **/

export const Footer = () => {
  function renderFooterMainElement() {
    return FooterMainElementData.map((item) => {
      return <FooterMainElement elementItem={item} />;
    });
  }

  function renderFooterContactInfo() {
    return FooterContactInfoData.map((item) => {
      return <FooterContactInfo infoItem={item} />;
    });
  }

  function renderFooterSocialLinks() {
    return FooterSocialLinksData.map((item) => {
      return <FooterSocialLinks SocialLinkItem={item} />;
    });
  }

  function renderFooterServiceLinks() {
    return FooterServiceLinksData.map((item) => {
      return <FooterServiceLinks serviceLinksItem={item} />;
    });
  }

  return (
    <div className='footerbackground' id='footer'>
      <div className='container'>
        <div className='row footerContentContainer'>
          <div className='col-md-4'>
            {renderFooterMainElement()}
            <div className='mt-5'>{renderFooterContactInfo()}</div>

            <div className='row'>{renderFooterSocialLinks()}</div>
          </div>

          <div className='col-md-8 ServiceAndLinks'>
            <div className='row' style={{ paddingLeft: "4rem" }}>
              {renderFooterServiceLinks()}
            </div>


            <div className='row' style={{ 'margin-top':'2rem' }}>
              <div className='col-2 footer-toTheTop'>
                <p className='footer-toTheTop-text'>To THE TOP</p>
              </div>
              <div className='col-1' style={{'padding':'0'}}>
                {/* <img
                  href='#home'
                  style={{ width: '1rem' }}
                  src={upToTop}
                  alt='to Top'  
                /> */}
                <a href='#home'><img style={{ width: '1.5rem'}}
                  src={upToTop}
                  alt='to Top' /></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
