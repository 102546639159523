import logo from "../../../images/logo.svg"
import contactEmail from "../../../images/contact-email.svg"
import contactCall from "../../../images/contact-telephone.svg"
import socialFb from "../../../images/social-fb.svg"
import socialInsta from "../../../images/social-insta.svg"
import socialSnap from "../../../images/social-snap.svg"
import socialTwitter from "../../../images/social-twitter.svg"


const FooterServiceLinksData = [
    {
        heading: "Services",
        headingUrl:"",
        title: [
            "Software development",
            "Web Development",
            "Mobile app development",
            "It management",
            "Search engine optimization",
            "Software as a service",
            "Business process outsourcing"
        ],
        url: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            
        ]
    },

    {
        heading: "Links",
        headingUrl:"",
        title: [
            "Home",
            "Team",
            "Our Clients",
            "Industries",
            "Services",
            "How We Work",
            "About"
        ],
        url: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            
        ]
    }
]

const FooterSocialLinksData = [
    {
        imageSrc: socialFb,
        url: ""
    },

    {
        imageSrc: socialInsta,
        url: ""
    },

    {
        imageSrc: socialSnap,
        url: ""
    },

    {
        imageSrc: socialTwitter,
        url: ""
    },

]

const FooterContactInfoData = [
    {
        iconSrc: contactEmail,
        contactInfo: "For Queries: info@easysolutions.pk"
    },

    {
        iconSrc: contactCall,
        contactInfo: "For call : +92 42 3578 6999"
    }
]



const FooterMainElementData = [ 
    {
    logoSrc: logo,
    subtitle: "We Deliver The Solutions You Need !"
    }
]

export {
    FooterContactInfoData,
    FooterSocialLinksData,
    FooterServiceLinksData,
    FooterMainElementData
}