import React from "react";
import logo from "../../images/logo.svg";

import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Container,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

function NavBar() {
  return (
    <Navbar className='navBarStyle sticky' id='header' bg='light' expand='lg'>
      <Container>
        <Navbar.Brand href='#home'>
          <img
            src={logo}
            width='160'
            height='120'
            className='d-inline-block align-top'
            alt='React Bootstrap logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          {/*  */}
          <Nav className='ms-auto'>
            <Nav.Link href='#home'>Home</Nav.Link>
            <Nav.Link href='#portfolio'>Portfolio</Nav.Link>
            <Nav.Link href='#work-section'>How We Work</Nav.Link>
            <Nav.Link href='#industry-section'>Industries</Nav.Link>
            <Nav.Link href='#services-section'>Services</Nav.Link>
            <Nav.Link href='#our-team'>Team</Nav.Link>
            <Nav.Link href='#ourclients'>Clients</Nav.Link>
            <Button className='navButtonStyle' href='#contactUs'>
              Contact
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
