import home_carousal_1a from "../../../images/home-CarousalComponentImg1.png";
import home_carousal_1b from "../../../images/home-CarousalComponentImg2.png";
import home_accounting from "../../../images/home-accounting.svg";
import home_cloud from "../../../images/home-cloud.svg";
import home_crm from "../../../images/home-crm.svg";
import home_hr from "../../../images/home-hr.svg";
import home_payroll from "../../../images/home-payroll.svg";
import home_purchase from "../../../images/home-purchase.svg";
import home_sales from "../../../images/home-sales.svg";
import home_stocks from "../../../images/home-stocks.svg";

const HomeSectionData = [
  {
    version: "v1",
    imgs: [home_carousal_1b, home_carousal_1a],
    bottomItems: [
      {
        imgSource: home_accounting,
        title: "Accounting",
        color: "#DE594E",
      },

      {
        imgSource: home_cloud,
        title: "Cloud",
        color: "#00B8BF",
      },

      {
        imgSource: home_crm,
        title: "CRM",
        color: "#DBD8DD",
      },

      {
        imgSource: home_hr,
        title: "HR",
        color: "#DE594E",
      },

      {
        imgSource: home_payroll,
        title: "Payroll",
        color: "#DBD8DD",
      },

      {
        imgSource: home_purchase,
        title: "Purchase",
        color: "#DBD8DD",
      },

      {
        imgSource: home_sales,
        title: "Sales",
        color: "#4DA0DC",
      },

      {
        imgSource: home_stocks,
        title: "Stocks",
        color: "#344D5B",
      },
    ],
  },

  {
    version: "v2",
    imgs: [home_carousal_1a],
    bottomItems: [],
  },
];

export { HomeSectionData };
