import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-simply-carousel";
import TeamCard from "../../../components/TeamCard/TeamCard";
import TeamData from "./TeamData";
import "./TeamSection.css";

function TeamSection() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [height, setHeight] = useState(window.innerHeight - 100);

  return (
    <div className='our-team' id='our-team' style={{ height }}>
      <Container>
        {/* heading */}
        <h1 className='heading text-color'>OUR TEAM</h1>
        {/* heading underline */}
        <div className='headingUnderline'></div>
        {/* card design */}

        <div>
          <Carousel
            updateOnItemClick
            itemsToShow={4}
            easing='linear'
            infinite={true}
            hideNavIfAllVisible
            style={{ flex: 1 }}
            itemsListProps={{
              style: {
                "data-transform": "translateX(-980)",
                transform: "translateX(-980)",
              },
            }}
            containerProps={{
              style: {
                width: "100%",
                justifyContent: "space-between",
              },
            }}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            itemsToShow={2}
            speed={400}
            forwardBtnProps={{
              children: ">",
              style: {
                width: 35,
                height: 35,
                minWidth: 35,
                alignSelf: "center",
                "border-radius": 17.5,
                background: "#fff",
                "box-shadow": "0px 10px 10px rgba(34, 31, 31, 0.14)",
                "border-color": "#fff",
              },
            }}
            backwardBtnProps={{
              children: "<",
              style: {
                width: 35,
                height: 35,
                minWidth: 35,
                alignSelf: "center",
                "border-radius": 17.5,
                background: "#fff",
                "box-shadow": "0px 10px 10px rgba(34, 31, 31, 0.14)",
                "border-color": "#fff",
              },
            }}
          >
            {TeamData.map((item, index) => (
              <TeamCard
                isSelected={index === activeSlide}
                key={index}
                imgSource={"https://picsum.photos/800/800/?random"}
                item={item}
              />
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
}

export default TeamSection;
