import React from 'react'
import "./FME.css"

/**
* @author
* @function FooterMainElement
**/

export const FooterMainElement = ({ elementItem }) => {

    const { logoSrc, subtitle } = elementItem;

    console.log( elementItem );
    return (
        <div >
            <div>
                <img
                    //   className='d-block w-100'
                    src={logoSrc}
                    alt='Logo'
                />
            </div>

            <div>
                <h1 className='hsubtitle'>{subtitle}</h1>
            </div>
        </div>
    )

}