import React from "react";
import "./WorkCard.css";

/**
 * @author
 * @function PortfolioCard
 **/

export const WorkCard = ({ className, workItem }) => {
  // console.log("Card Rendetred ",className);
  const { imgSource, heading, number, arrowImage } = workItem;
  return (
    <div className={className}>
      <div>
        <div>
          <h1 className='work-item-number'>{number}</h1>
        </div>
        <div className='work-img'>
          <img src={imgSource} alt='First slide' />
        </div>
        <div>
          <h1 className='work-heading'>{heading}</h1>
        </div>
        <div className='work-arrow-img'>
          <img
            src={arrowImage}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt='First slide'
          />
        </div>
      </div>
    </div>
  );
};
