import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ClientCard } from "../../../components/ClientCard/ClientCard";
import ClientArray from "./ClientData";
import "./OurClients.css";

/**
 * @author
 * @function OurClients
 **/

export const OurClients = (props) => {
  const [screenDimension, setScreenDimension] = useState("");
  console.log("The screen Dimension", screenDimension);
  const [height, setHeight] = useState(window.innerHeight - 100);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screen = "";
      if (window.innerWidth < 1200 && window.innerWidth > 760) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 760) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const renderClientItem = (dim) => {
    return ClientArray.map((item, index) => {
      // console.log("The FUnction Returns", getBootStrapClassName(dim));
      return (
        <ClientCard
          className={getBootStrapClassName(dim, index)}
          imgSource={item}
        />
      );
    });
  };

  // function getSpacingClass(index, screenDimension) {
  //   if (screenDimension === "tablet") {
  //     if (index === 0 || index === 2) {
  //       return "itemTwoAndFour";
  //     } else if (index === 4 || index === 3) {
  //       return "itemOneAndFive";
  //     } else return "itemThree";
  //   } else if (screenDimension === "mobile") {
  //     return "itemThree";
  //   } else {
  //     if (index === 0 || index === 4) {
  //       return "itemOneAndFive";
  //     } else if (index === 1 || index === 3) {
  //       return "itemTwoAndFour";
  //     } else return "itemThree";
  //   }
  // }

  function getBootStrapClassName(screenDimension, index) {
    console.log("Get BootTsrap Function ", screenDimension);
    if (screenDimension === "tablet") {
      return "col-4 clientItem col-center-block";
    } else if (screenDimension === "mobile") {
      return "col-6 clientItem";
    } else {
      return "col-2 clientItem";
    }
  }

  return (
    <div className='ourclients' id='ourclients'>
      <div className='content'>
        <Container>
          {/* heading */}
          <h1 className='heading text-color'>OUR CLIENTS</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* card design */}

          <div className='row portfolioItemContainer'>
            {renderClientItem(screenDimension)}
          </div>
        </Container>
      </div>
    </div>
  );
};
