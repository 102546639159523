import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IndustryCard } from "../../../components/IndustryCard/IndustryCard";
import IndustriesData from "./IndustriesData";

import "./IndustrySection.css";

/**
 * @author
 * @function IndustrySection
 **/

export const IndustrySection = (props) => {
  const [screenDimension, setScreenDimension] = useState("");
  console.log("The screen Dimension", screenDimension);
  const [height, setHeight] = useState(window.innerHeight - 100);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screen = "";
      if (window.innerWidth < 1200 && window.innerWidth > 1000) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 990) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const renderIndustryItem = (dim) => {
    var className = "";
    if (dim === "tablet" || dim === "") {
      className = "col-3 custom-width   industry-item";
    } else {
      className = "col-6 custom-width industry-item";
    }

    return IndustriesData.map((item) => {
      return <IndustryCard className={className} industryItem={item} />;
    });
  };

  // getBootStrapClassName(dim, i)

  // function getBootStrapClassName(screenDimension, index) {
  //   console.log("Get BootTsrap Function ", screenDimension);
  //   if (screenDimension === "tablet") {
  //     return "col-4 work-item";
  //   } else if (screenDimension === "mobile") {
  //     return "col work-item";
  //   } else {
  //     return "col-4 work-item";
  //   }
  // }

  return (
    <div className='industry-section' id='industry-section'>
      <div className='content'>
        <Container>
          {/* heading */}
          <h1 className='heading text-color'>Industries</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* card design */}

          <div className='col-12'>
            <div className='row portfolioItemContainer'>
              {renderIndustryItem(screenDimension)}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
