import ClientCard1 from "../../../images/Clientcard1.svg"
import ClientCard2 from "../../../images/Clientcard2.svg"
import ClientCard3 from "../../../images/Clientcard3.svg"
import ClientCard4 from "../../../images/Clientcard4.svg"
import ClientCard5 from "../../../images/Clientcard5.svg"
import ClientCard6 from "../../../images/Clientcard6.svg"
import ClientCard7 from "../../../images/Clientcard7.svg"
import ClientCard8 from "../../../images/Clientcard8.svg"
import ClientCard9 from "../../../images/Clientcard9.svg"
import ClientCard10 from "../../../images/Clientcard10.svg"
import ClientCard11 from "../../../images/Clientcard11.svg"
import ClientCard12 from "../../../images/Clientcard12.svg"
import ClientCard13 from "../../../images/Clientcard13.svg"
import ClientCard14 from "../../../images/Clientcard14.svg"
import ClientCard15 from "../../../images/Clientcard15.svg"
import ClientCard16 from "../../../images/Clientcard16.svg"
import ClientCard17 from "../../../images/Clientcard17.svg"
import ClientCard18 from "../../../images/Clientcard18.svg"
import ClientCard19 from "../../../images/Clientcard19.svg"
import ClientCard20 from "../../../images/Clientcard20.svg"
import ClientCard21 from "../../../images/Clientcard21.svg"
import ClientCard22 from "../../../images/Clientcard22.svg"
import ClientCard23 from "../../../images/Clientcard23.svg"
import ClientCard24 from "../../../images/Clientcard24.svg"
import ClientCard25 from "../../../images/Clientcard25.svg"

// console.log("data from clients",abc);


let cards =[]


const getClientCard =()  =>{

            cards.push(ClientCard1)
            cards.push(ClientCard2)
            cards.push(ClientCard3)
            cards.push(ClientCard4)
            cards.push(ClientCard5)
            cards.push(ClientCard6)
            cards.push(ClientCard7)
            cards.push(ClientCard8)
            cards.push(ClientCard9)
            cards.push(ClientCard10)
            cards.push(ClientCard11)
            cards.push(ClientCard12)
            cards.push(ClientCard13)
            cards.push(ClientCard14)
            cards.push(ClientCard15)
            cards.push(ClientCard16)
            cards.push(ClientCard17)
            cards.push(ClientCard18)
            cards.push(ClientCard19)
            cards.push(ClientCard20)
            cards.push(ClientCard21)
            cards.push(ClientCard22)
            cards.push(ClientCard23)
            cards.push(ClientCard24)
            cards.push(ClientCard25)
    

    return cards;
}

export default getClientCard();


