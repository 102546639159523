import React from "react";
import qouteImage from "../../images/qoutes_img.svg";
import "./TeamCard.css";

function TeamCard({
  item: { memberName, memberDesignation, jobDesc },
  imgSource,
  isSelected,
}) {
  console.log(memberName, memberDesignation, imgSource);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <img className='team-card-image' src={imgSource} alt='First slide' />

      <div className={isSelected ? "team-card-active" : "team-card"}>
        <div>
          <h1 className='team-heading'>{memberName}</h1>
        </div>
        <div>
          <h1 className='team-desig'>{memberDesignation}</h1>
        </div>

        <div className='team-qoute-img'>
          <img
            src={qouteImage}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt='First slide'
          />
        </div>

        <div>
          <h1 className='team-jd'>{jobDesc}</h1>
        </div>
      </div>
    </div>
  );
}

export default TeamCard;

// style={{ flexDirection: "row", justifyContent: "center" }}
