import React from "react";
import "./FSL.css";

/**
 * @author
 * @function FooterServiceLinks
 **/

export const FooterServiceLinks = ({ serviceLinksItem }) => {
  console.log(serviceLinksItem);
  const { heading, headingUrl, title, url } = serviceLinksItem;
  console.log("array lngth", title.length);

  function renderLinks(title, url) {
    // url.reduce((acc, current, index) => {
    //     console.log(current, title[index])
    //     return [...acc, current, title[index]]
    //  }, [])

    return title.map((item, index) => {
      return (
        <li>
          <a className='sublinks' href={url[index]}>
            {item}
          </a>
        </li>
      );
    });

    // for (let i = 0; i <= title.length; i++) {
    //      <li><a href={url[i]}>{title[i]}</a></li>
    // }
  }

  return (
    <div className='col' style={{ paddingLeft: "8rem" }}>
      <div>
        <a className='FSL-heading' href={headingUrl}>
          {heading}
        </a>
      </div>

      <div>
        <ul>
          <div className='mt-2'>{renderLinks(title, url)}</div>
        </ul>
      </div>
    </div>
  );
};
