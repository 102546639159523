import React from 'react'
import { Button } from 'react-bootstrap'
import './Button.css'

/**
* @author
* @function ContactButton
**/

export const ContactButton = ({buttonText}) => {

    return (
        <Button
            className='contactButtonStyle'
        >{buttonText}</Button>
    )
}
