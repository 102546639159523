const TeamData = [
  {
    memberName: "Tahir Mahmood",
    memberDesignation: "FOUNDER & CEO",
    jobDesc: "Process Automation, ERP Design, Development & Implementation",
  },
  {
    memberName: "Fahd Amin",
    memberDesignation: "DIRECTOR",
    jobDesc:
      "Functional Consultant, Process Automation, SOPs & Controls, ERP Design & Implementation",
  },
  {
    memberName: "Usman Rauf",
    memberDesignation: "DIRECTOR",
    jobDesc: "Advisory & Functional Consultant",
  },
  {
    memberName: "Software Development",
    memberDesignation: "FOUNDER & CEO",
    jobDesc: "Process Automation, ERP Design, Development & Implementation",
  },
  {
    memberName: "Software Development",
    memberDesignation: "FOUNDER & CEO",
    jobDesc: "Process Automation, ERP Design, Development & Implementation",
  },
];

export default TeamData;
