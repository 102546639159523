import retention from "../../../images/p-retention.svg"
import quality from "../../../images/p-quality.svg"
import excellence from "../../../images/p-excellence.svg"
import businessDomain from "../../../images/p-businessDomain.svg"
import customers from "../../../images/p-customers.svg"


const PortfolioData = [
    {
        imgSource:excellence,
        headingNum:"20",
        headingSuf:"+",
        text:"Years Of Excellence"
    },
    {
        imgSource:customers,
        headingNum:"100",
        headingSuf:"+",
        text:"Customers"
    },
    {
        imgSource:quality,
        headingNum:"100",
        headingSuf:"%",
        text:"Quality"
    },
    {
        imgSource:retention,
        headingNum:"100",
        headingSuf:"%",
        text:"Retention"
    },
    {
        imgSource:businessDomain,
        headingNum:"15",
        headingSuf:"+",
        text:"Business Domains"
    },

];

export default PortfolioData;