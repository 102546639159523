import React from 'react'
import '../ContactForm/ContactForm.css'

/**
* @author
* @function ContactForm
**/

export const ContactForm = ({className,formTextItem,isTextArea}) => {

    const {defaultText} = formTextItem
    console.log(isTextArea);

  return(
    <div className={className}>
      {
        isTextArea 
        ? <input className="contactForm form-control" placeholder={defaultText}></input> :
        <textarea class="form-control contactForm"  id="comment" placeholder={defaultText} ></textarea>
      }
       
    </div>
   )

 }