import React, { useState, useEffect } from "react";
import "../ContactUs/ContactUs.css";
import { ContactCardList } from "../../../components/ContactUsComponents/ContactCardList/ContactCardList";
import { ContactForm } from "../../../components/ContactUsComponents/ContactForm/ContactForm";
import { ContactFormData } from "../../../components/ContactUsComponents/ContactForm/ContactFormData";
import { ContactButton } from "../../../components/ContactUsComponents/Button/Button";
import { ContactIllustration } from "../../../components/ContactUsComponents/Illustration/ContactIllustration";
import ContactUsIll from "../../../images/contactUsIllustration.svg";

/**
 * @author
 * @function ContactUs
 **/

export const ContactUs = (props) => {
  const [screenDimension, setScreenDimension] = useState("");
  //const [height, setHeight] = useState(window.innerHeight - 80);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screen = "";
      if (window.innerWidth > 1200) {
        screen = "tablet";
        setScreenDimension(screen);
      } else if (window.innerWidth < 1200) {
        screen = "mobile";
        setScreenDimension(screen);
      } else {
        screen = "";
        setScreenDimension(screen);
      }
    });
  }, []);

  const getColoumnClass = (dim) => {
    if (dim === "tablet" || dim === "") {
      return "col-4 contactCard-parentContainer";
    } else {
      return "col-6 contactCard-parentContainer";
    }
  };

  function rendeContacForm() {
    return ContactFormData.map((item, index) => {
      let className =
        index < 3
          ? " row contactFormContainer contactForm-height-50"
          : " row contactFormContainer contactForm-height-150 div-flex";
      return (
        <ContactForm
          formTextItem={item}
          className={className}
          isTextArea={index !== 3}
        />
      );
    });
  }

  return (
    <div className='contactUs' id='contactUs'>
      <div className='content'>
        <div className='container'>
          {/* heading */}
          <h1 className='contactUs-heading'>CONTACT US</h1>
          {/* heading underline */}
          <div className='headingUnderline'></div>
          {/* card design */}
          <div className='row'>
            <div className={`${getColoumnClass(screenDimension)}`}>
              <ContactCardList />
            </div>

            <div
              className={`${getColoumnClass(
                screenDimension
              )} contactUs-formParentContainer`}
            >
              {rendeContacForm()}
            </div>

            <div className={`${getColoumnClass(screenDimension)}`}>
              <ContactIllustration contactImageItem={ContactUsIll} />
            </div>

            <ContactButton buttonText='SUBMIT' />
          </div>
        </div>
      </div>
    </div>
  );
};
