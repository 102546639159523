import React, { useRef } from "react";
import CountUp from "react-countup";
import "./PortfolioCard.css";

/**
 * @author
 * @function PortfolioCard
 **/

export const PortfolioCard = ({ className, cardItem, itemNumber, ref }) => {
  // console.log("Card Rendetred ",className);
  const { imgSource, headingNum, headingSuf, text } = cardItem;

  return (
    <div className={className}>
      <div className={`card portfolioItem ${itemNumber}`}>
        <div className='pImgSpacing'>
          <img src={imgSource} alt='First slide' />
        </div>
        <div>
          <h1 className='pItemHeading'>
            <CountUp
              end={headingNum}
              suffix={headingSuf}
              duration={4}
              redraw={true}
            />
          </h1>
        </div>
        <div>
          <h1 className='card-text'>{text}</h1>
        </div>
      </div>
    </div>
  );
};
