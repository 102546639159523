import React from 'react'
import './FSsL.css'

/**
* @author
* @function FooterSocialLinks
**/

export const FooterSocialLinks = ({SocialLinkItem}) => {
    const { imageSrc, url } = SocialLinkItem

    return (
        
            <div className='col-2 mt-2'>
                <a href={url}>
                    <img 
                    className='socialicon'
                    src={imageSrc}
                    alt="SL" 
                    />
                </a>
            </div>

        
    )

}