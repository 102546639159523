const ContactFormData = [
    {
        defaultText:"Your Name",
    },
    {
        defaultText:"Your Email",
    },
    {
        defaultText:"Subject",
    },
    {
        defaultText:"Message",
    },
]

export {
    ContactFormData
}