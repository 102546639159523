import React from 'react'
import '../Illustration/ContactIllustration.css'

/**
* @author
* @function ContactIllustration
**/

export const ContactIllustration = ({contactImageItem}) => {


  return(
    <div className=''>
        <img
        className='contactIllustration'
        src={contactImageItem}
        alt='ContactIllustration'
        />
    </div>
   )

 }