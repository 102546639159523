//======================= I N D U S T R I E S - ICONS ==================================

import serSoftwareDevelopment from '../../../images/ser-softwareDevelopment.svg'
import serWebDevelopment from '../../../images/ser-webDevelopment.svg'
import serMobileDevelopment from '../../../images/ser-mobileDevelopment.svg'
import serItManagement from '../../../images/ser-itManagement.svg'
import serSeo from '../../../images/ser-seo.svg'
import serSoftwareAsAService from '../../../images/ser-softwareAsAService.svg'
import serBusinessProcess from '../../../images/ser-businessProcess.svg'

//======================= I N D U S T R I E S - ICONS BW ==================================

import serBWSoftwareDevelopment from '../../../images/ser-BW-softwareDevelopment.svg'
import serBWWebDevelopment from '../../../images/ser-BW-webDevelopment.svg'
import serBWMobileDevelopment from '../../../images/ser-BW-mobileDevelopment.svg'
import serBWItManagement from '../../../images/ser-BW-itManagement.svg'
import serBWSeo from '../../../images/ser-BW-seo.svg'
import serBWSoftwareAsAService from '../../../images/ser-BW-softwareAsAService.svg'
import serBWBusinessProcess from '../../../images/ser-BW-businessProcess.svg'

//======================= I N D U S T R I E S - MODS ==================================

import sermodSoftwareDevelopment from '../../../images/ser-mod-softwareDevelopment.svg'
import sermodWebDevelopment from '../../../images/ser-mod-webDevelopment.svg'
import sermodMobileDevelopment from '../../../images/ser-mod-mobileDevelopment.svg'
import sermodItManagement from '../../../images/ser-mod-itManagement.svg'
import sermodSeo from '../../../images/ser-mod-seo.svg'
import sermodSoftwareAsAService from '../../../images/ser-mod-softwareAsAService.svg'
import sermodBusinessProcess from '../../../images/ser-mod-businessProcess.svg'

//======================= I N D U S T R I E S - ILLUSTRATIONS ==================================

import seraiSoftwareDevelopment from '../../../images/ser-ai-softwareDevelopment.svg'
import seraiWebDevelopment from '../../../images/ser-ai-webDevelopment.svg'
import seraiMobileDevelopment from '../../../images/ser-ai-mobileDevelopment.svg'
import seraiItManagement from '../../../images/ser-ai-itManagement.svg'
import seraiSeo from '../../../images/ser-ai-seo.svg'
import seraiSoftwareAsAService from '../../../images/ser-ai-softwareAsAService.svg'
import seraiBusinessProcess from '../../../images/ser-ai-businessProcess.svg'

const ServiceData = [
  {
    heading: "Software Development",
    serIcon: serSoftwareDevelopment,
    serBwIcon:serBWSoftwareDevelopment,
    serviceDetailProp: {
      tagline: "Rapidly and reliably build great software products to amaze our customers",
      paragraph1:"Easy Solutions' IT strategists are fanatic about delivering end-to-end custom software development services. Our expertise in Oracle, DB2, VB.NET, C#, Java, MySQL, MSSQL and various other open source and legacy software platforms help us in ubiquitous software development.",
      moduleImg: sermodSoftwareDevelopment,
      paragraph2:"So, your search for the best custom software development company ends here! We will guide you through the entire software development life cycle (SDLC), which includes understanding and analyzing your requirements, designing, developing, debugging (software testing), deploying and software support services.",
      serviceImg: seraiSoftwareDevelopment,
      colorCode: "#6a4094",
    },
  },
  {
    heading: "Web Development",
    serIcon: serWebDevelopment,
    serBwIcon:serBWWebDevelopment,
    serviceDetailProp: {
      tagline: "We love building awesome web applications",
      paragraph1:"Easy Solutions has been promptly delivering professional web solutions inclusive of web designing, web development, web content management, e-commerce, corporate websites and enterprise portals. We have language experts in .NET, ROR, PHP, Python, NodeJS, JavaScript, HTML, XHTML, CSS and many more.",
      moduleImg: sermodWebDevelopment,
      paragraph2:"We will definitely be the perfect choice of your web development partner as we have the right expertise in business domains and cutting edge /emerging technologies and we can assure you the best results and help you gain the level of success that you desire.",
      serviceImg: seraiWebDevelopment,
      colorCode: "#417dc0",
    },
  },
  {
    heading: "Mobile Application Development",
    serIcon: serMobileDevelopment,
    serBwIcon:serBWMobileDevelopment,
    serviceDetailProp: {
      tagline: "We love building awesome mobile apps",
      paragraph1:"Prolific and feature-rich Mobile App development services at Easy Solutions allow you to define your boundaries much wider in your industrial domain while help in approaching your business problems with a rather futuristic approach. Easy Solutions helps you getting native and hybrid mobile app development for leading platforms.Building mobile apps for the two most popular platforms, iOS and Android, we ensure convenient and transparent communication throughout the whole process.",
      moduleImg: sermodMobileDevelopment,
      paragraph2:"At Easy Solutions, hire our expert mobile app developers and the affirmation of quality, feature-richness, design attributes and escalating overall app experience. We are a mobile application development company that not only crafts engaging mobile app experience but we place you in extreme business mobilizing ecosystem that results in high business automation and stand-alone position in the vertical.",
      serviceImg: seraiMobileDevelopment,
      colorCode: "#219dc4",
    },
  },
  {
    heading: "IT Management",
    serIcon: serItManagement,
    serBwIcon:serBWItManagement,
    serviceDetailProp: {
      tagline: "WE TELL I.T. Like IT IS",
      paragraph1:"Easy Solutions offers a comprehensive set of voice and data solutions developed by a team of engineers with well over 15 years of cumulative experience. We understand that your computer network is a vital element of your business. Our experts such as VoIP Certiï€ed Specialists, Microsoft Certified Engineers (MCSE), Cisco Certified Network Associates (CCNA) and VMware Certified Professionals (VCP) make sure your network becomes an outstanding business asset. Let us help you bring your IT Management back down to earth with some of the most experienced straight-shooting engineers in the area and real-world networking solutions.At Easy Solutions, we provide companies with the innovative computer network infrastructure design to facilitate optimum performance and availability.",
      moduleImg: sermodItManagement,
      paragraph2:"Let us help you bring your IT Management back down to earth with some of the most experienced straight-shooting engineers in the area and real-world networking solutions.",
      serviceImg: seraiItManagement,
      colorCode: "#206292",
    },
  },
  {
    heading: "Search Engine Optimization",
    serIcon: serSeo,
    serBwIcon:serBWSeo,
    serviceDetailProp: {
      tagline: "Choose us as your SEO partner",
      paragraph1:"With a proven, task -driven system, we run your campaign with the highest possible efficiency, maximizing your SEO investment at all times. Easy Solutions gives you monthly reports that are easy to read, and we provide detail of the work, results, highlights and challenges that occurred. And of course, we welcome your questions, comments and ideas at any time.Our strategic and technical efforts always stay focused on lead generation or online revenue. Executing SEO takes wide range of B2B and B2C skills in coordination of Copywriters, Graphic designers, Web designers & Web developers",
      moduleImg: sermodSeo,
      paragraph2:"We at Easy Solutions can help you in scaling your business and get the digital visibility you need to thrive.",
      serviceImg: seraiSeo,
      colorCode: "#3da19a",
    },
  },
  {
    heading: "Service As A Software",
    serIcon: serSoftwareAsAService,
    serBwIcon:serBWSoftwareAsAService,
    serviceDetailProp: {
      tagline: "The intelligent platform to manage the life cycle of your software needs",
      paragraph1:"Software-as-a-Service applications is the best business solution delivery model of latest generation in which the application is hosted remotely on the solution provider's infrastructure. The solution users don't need to download or install any other software other than a browser or mobile app.The model saves time and resources on development, up-gradation, distribution, marketing, and client conversation. Also, since all the infrastructure is managed by us, the client can focus on his business domain rather than wasting time on conï€guring and understanding the software, managing servers and taking backups of important data regularly.",
      moduleImg: sermodSoftwareAsAService,
      paragraph2:"Easy Solutions will be with you at every step from developing first viable product to scale it into a giant solution. We have vastly experienced team of developers and IT specialists who have been creating Cloud Solutions for clients across domains â€“ Education, Manufacturing, Retail, OMC, Document Tracking & Archiving, Human Capital, Distribution, Health Care, Non-Profit and many more",
      serviceImg: seraiSoftwareAsAService,
      colorCode: "#cb818b",
    },
  },
  {
    heading: "Business Process Outsourcing",
    serIcon: serBusinessProcess,
    serBwIcon:serBWBusinessProcess,
    serviceDetailProp: {
      tagline: "Hire us in any domain",
      paragraph1:"At Easy Solutions, our goals are to help you reach your business targets faster and to increase your competitiveness. Our expert Business Consulting services help you make changes quickly, so you can satisfy customers and easily adapt to new requirements. We also help you in formation of cultured corporate world with skilled manpower through quality trainings.",
      moduleImg: sermodBusinessProcess,
      paragraph2:"Easy Solutions helps you meet your unique business process requirements with a comprehensive, customizable package of Business Process Outsourcing, Consulting and Training Services.",
      serviceImg: seraiBusinessProcess,
      colorCode: "#3da19a",
    },
  },
];

export default ServiceData;
