import React, { useState } from "react";
import { OutlineButton } from "../common";
import "./IndustryCard.css";
import { MyVerticallyCenteredModal } from "../Modal/Modal";

/**
 * @author
 * @function IndustryCard
 **/

export const IndustryCard = ({ className, industryItem }) => {
  // console.log("Card Rendetred ",className);
  const { indIcon, heading, industryModalProp } = industryItem;
  const [modalShow, setModalShow] = useState(false);
  console.log("this is modal show", modalShow);

  const handleShow = () => {
    setModalShow(!modalShow);
  };

  const renderModal = () => {
    return (
      <MyVerticallyCenteredModal
        show={modalShow}
        modalItem={industryModalProp}
        onHide={() => setModalShow(false)}
      />
    );
  };

  return (
    <div className={className}>
      <div>
        <div className='industry-img'>
          <img src={indIcon} alt='First slide' />
        </div>
        <div>
          <h1 className='industry-heading'>{heading}</h1>
        </div>

        <div className='industry-button-container'>
          <OutlineButton
            value={heading}
            text='Read More'
            onClick={handleShow}
          />
        </div>
      </div>
      {modalShow ? renderModal() : null}
    </div>
  );
};
