import Call from "../../../images/ContactCardImage1.svg"
import Email from "../../../images/ContactCardImage2.svg"
import Location from "../../../images/ContactCardImage3.svg"


const ContactCardData = [
    {
        image:Call,
        description:"Have A Query ? Don't Hesitate To Call Us.",
        Info1:"+92 42 3578 6999",
        Info2:"+92 321 477 3981",
        Info3:"+1 609 664 1691",
    },
    {
        image:Email,
        description:"You Can Email Us On Following Addresses.",
        Info1:"info@easysolutions.pk",
        Info2:"sales@easysolutions.pk",
        Info3:"",
    },
    {
        image:Location,
        description:"We Are Located In USA & Pakistan. Come Visit Us!",
        Info1:"Pakistan: 406-Eden heights,Jail Rd, Lahore",
        Info2:"USA: Princeton, NJ",
        Info3:"",
    }
]

export {
    ContactCardData
}